import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {  
    opacity: 0; 
  }
  100% {
    opacity: 1;
  }
`

const loadingBarFill = keyframes`
0% {  
    transform: translateX(-100%);
  }
  50% {  
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoadingBar = styled.div`
  width: 148px;
  height: 4px;
  border-radius: 20px;
  overflow: hidden;
  background: ${NeutralColors.Grey300};
  opacity: 0;
  animation: ${fadeIn} 0.8s ease-in-out;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
`

export const LoadingBarFill = styled.div`
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  background: ${NeutralColors.Ink};
  border-radius: 20px;
  animation: ${loadingBarFill} 3s ease-in-out infinite;
  animation-delay: 1s;
`
