import { PALETTES } from 'constants/palettes'
import {
  IMAGE_LOADING,
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
} from 'constants/image'

import PropTypes from 'prop-types'

export const PICTURE_PROP_TYPES = {
  role: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  loading: PropTypes.oneOf(Object.values(IMAGE_LOADING)),
  decoding: PropTypes.oneOf(Object.values(IMAGE_DECODING)),
  fetchpriority: PropTypes.oneOf(Object.values(IMAGE_FETCHPRIORITY)),
}

export const IMAGE_PROP_TYPES = {
  url: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  contentType: PropTypes.string,
  loading: PropTypes.oneOf(Object.values(IMAGE_LOADING)),
  decoding: PropTypes.oneOf(Object.values(IMAGE_DECODING)),
  fetchpriority: PropTypes.oneOf(Object.values(IMAGE_FETCHPRIORITY)),
}

export const AUDIO_PROP_TYPES = PropTypes.shape({
  url: PropTypes.string,
  title: PropTypes.string,
})

export const QUOTE_CONTENT_PROP_TYPES = {
  author: PropTypes.string,
  position: PropTypes.string,
  avatar: PropTypes.shape(IMAGE_PROP_TYPES),
  text: PropTypes.string,
  markdownText: PropTypes.string,
  audio: AUDIO_PROP_TYPES,
  tweetable: PropTypes.bool,
  hashtags: PropTypes.arrayOf(PropTypes.string),
  center: PropTypes.bool,
}

export const QUOTE_PROP_TYPES = {
  content: PropTypes.shape(QUOTE_CONTENT_PROP_TYPES),
  color: PropTypes.string,
  disableLazyLoad: PropTypes.bool,
  currentUrl: PropTypes.string,
  center: PropTypes.bool,
}

export const USER_PROP_TYPE = PropTypes.shape({
  email: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
})

export const childrenPropType = PropTypes.node

export const LOCALE = {
  EN: 'en',
  ES: 'es',
}

export const UPCOMING_LOCALE = {
  DE: 'de',
  FR: 'fr',
}

export const ALL_LOCALES = {
  ...LOCALE,
  ...UPCOMING_LOCALE,
} as const

export const DEFAULT_LOCALE = LOCALE.EN

export const localesPropType = PropTypes.oneOf(Object.values(LOCALE))

export const upcomingLocalesPropType = PropTypes.oneOf(
  Object.values(UPCOMING_LOCALE)
)

export const allLocalesPropType = PropTypes.oneOf(Object.values(ALL_LOCALES))

export const refPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.any,
  }),
])

export const palettePropType = PropTypes.oneOf(PALETTES)

export const BREAKPOINT_CONFIG_PROP_TYPE = {
  base: PropTypes.any,
  xs: PropTypes.any,
  sm: PropTypes.any,
  md: PropTypes.any,
  lg: PropTypes.any,
}
